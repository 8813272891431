import React, {Fragment, useEffect, useRef, useState} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import Modal from "../Modal";
import firebase from "firebase";
import Layout from "../layout";
import SEO from "../seo";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import {getFirebase, getUiConfig, uiConfig} from "../../utils/signin";

const ShoppingListReviewModal = ({
                                     isOpen = false,
                                     handleShowError,
                                     sendAlertEmailToAuthor,
                                     setIsLoadingMutation,
                                     setIsOpen,
                                     id,
                                     product: productInit
                                 }) => {
    const [rating, setRating] = useState(4);
    const [product, setProduct] = useState(productInit);
    const firebase = getFirebase();
    useEffect(() => {
        if (!firebase) return;
        if (!productInit) return;

        setProduct(productInit);
        const {review = {}} = productInit;
        setRating(review.rating || 4);
    }, [productInit]);
    if (!product) return null;

    const {url, review = {}} = product;
    const {message} = review;
    const handleAddReview = (event) => {
        event.preventDefault();
        setIsLoadingMutation(true);
        const newReview = {
            message: event.target.message.value,
            rating,
            createdAt: new Date(),
        }
        const firestoreDb = getFirebase().firestore();
        firestoreDb.collection(`shopping-lists`).doc(id).set({
            products: {
                [product.id]: {
                    review: newReview
                }
            }
        }, {merge: true})
            .then(() => {
                setProduct({...product, review: newReview})
                setIsOpen(false);
                setIsLoadingMutation(false);
                sendAlertEmailToAuthor();
            })
            .catch((error) => {
                handleShowError();
                console.error("Error adding document: ", error);
            });
    }
    const handleOpenProduct = () => {
        window.open(url)
    }
    const handleRating = (i) => {
        setRating(i);
    }


    return (
        <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
            <div
                className="inline-block w-full max-w-md my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <div className="relative  sm:max-w-xl sm:mx-auto">
                    <div className="relative px-4 py-10 bg-white mx-8 md:mx-0 shadow rounded-3xl sm:p-10">
                        <div className="max-w-md mx-auto">
                            <div className="flex items-center space-x-5">
                                <div
                                    className="h-14 w-14 bg-yellow-200 rounded-full flex flex-shrink-0 justify-center items-center text-yellow-500 text-2xl font-mono">i
                                </div>
                                <div className="block pl-2 font-semibold text-xl self-start text-gray-700">
                                    <h2 className="leading-relaxed">Ajouter un avis</h2>
                                    <p className="text-sm text-gray-500 font-normal leading-relaxed">
                                        Laissez savoir à votre décorateur si ce produit vous plaît ou non.
                                    </p>
                                </div>
                            </div>
                            <button
                                onClick={handleOpenProduct}
                                className="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 mt-6 rounded-md focus:outline-none">
                                Voir le produit
                            </button>
                            <form className="divide-y divide-gray-200" onSubmit={handleAddReview}>
                                <div
                                    className="py-8 text-base leading-6 space-y-4 text-gray-700 sm:text-lg sm:leading-7">
                                    <label className="leading-loose">Avis</label>
                                    <div className="flex space-x-3">
                                        {[1, 2, 3, 4, 5].map(i => {
                                            const selectedMaybeClassName = rating >= i ? 'text-red-500' : 'text-gray-500';
                                            return (
                                                <svg onClick={() => handleRating(i)}
                                                     xmlns="http://www.w3.org/2000/svg"
                                                     className={`w-12 h-12 cursor-pointer ${selectedMaybeClassName}`}
                                                     viewBox="0 0 20 20" fill="currentColor">
                                                    <path fill-rule="evenodd"
                                                          d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                                          clip-rule="evenodd"/>
                                                </svg>
                                            )
                                        })}
                                    </div>
                                    <div className="flex flex-col">
                                        <label className="leading-loose">Message</label>
                                        <textarea
                                            defaultValue={message}
                                            name='message'
                                            placeholder="Optionnel"
                                            type="text"
                                            className="px-4 py-2 border focus:ring-gray-500 focus:border-gray-900 w-full sm:text-sm border-gray-300 rounded-md focus:outline-none text-gray-600 resize-none"
                                            rows="6"></textarea>
                                    </div>
                                </div>
                                <div className="pt-4 flex items-center space-x-4">
                                    <button
                                        onClick={() => setIsOpen(false)}
                                        type="reset"
                                        className="flex justify-center items-center w-full text-gray-900 px-4 py-3 rounded-md focus:outline-none">
                                        <svg className="w-6 h-6 mr-3" fill="none" stroke="currentColor"
                                             viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}
                                                  d="M6 18L18 6M6 6l12 12"/>
                                        </svg>
                                        Fermer
                                    </button>
                                    <button
                                        type="submit"
                                        className="bg-blue-500 flex justify-center items-center w-full text-white px-4 py-3 rounded-md focus:outline-none">Envoyer
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ShoppingListReviewModal;

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {StaticQuery, graphql, navigate, Link} from "gatsby";

import AuthModal from "./AuthModal";
import firebase from "firebase";
import logoImg from "../images/logo.png";
import {getFirebase} from "../utils/signin";
import Footer from "./footer";

function VisitorLayout({children}) {
    const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const firebase = getFirebase();
    useEffect(() => {
        if (!firebase) return;

        const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
        });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);
    const handlerRedirectToMyProducts = () => {
        navigate('my-products');
    }
    const signInSuccessHandler = (authResult, redirectUrl) => {
        handlerRedirectToMyProducts();
    }
    const handleOpenAuthModal = () => {
        if (isSignedIn) {
            handlerRedirectToMyProducts();
            return;
        }

        setIsOpenAuthModal(true);
    }

    return (
        <StaticQuery
            query={graphql`
        query VisitorSiteTitleQuery {
          site {
            siteMetadata {
              title,
              github,
              blog
            }
          }
        }
      `}
            render={(data) => (
                <body className="leading-normal tracking-normal  flex flex-col">


                {/* Navigation */}
                <nav id="header" className="fixed w-full z-30 top-0 text-white bg-white shadow">

                    <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">

                        <div className="pl-4 flex items-center">
                            <a className="toggleColour no-underline hover:no-underline font-bold text-xl lg:text-4xl text-gray-800"
                               href="/">
                                <img className="h-16 fill-current inline" src={logoImg}/>DeerHome
                            </a>
                        </div>

                        <div className="block lg:hidden pr-4">
                            <button id="nav-toggle"
                                    className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 lg:bg-transparent text-black p-4 lg:p-0 z-20 bg-white">
                                <svg className="fill-current h-3 w-3" viewBox="0 0 20 20"
                                     xmlns="http://www.w3.org/2000/svg"><title>Menu</title>
                                    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                                </svg>
                            </button>
                        </div>
                    </div>

                    <hr className="border-b border-gray-100 opacity-25 my-0 py-0"/>
                </nav>
                <AuthModal signInSuccessHandler={signInSuccessHandler} isOpen={isOpenAuthModal} setIsOpen={setIsOpenAuthModal}/>


                <div className='flex-1'>
                {children}
                </div>

                {/* Footer */}
                <Footer/>

                </body>
            )}
        />
    );
}

VisitorLayout.propTypes = {
    children: PropTypes.node.isRequired
};

export default VisitorLayout;

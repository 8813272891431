import {Link} from "gatsby";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {navigate} from "gatsby-link";

function ProductWithReview({title, price, image, id, review, handleClickProduct, units = 1}) {
    const [isExpanded, toggleExpansion] = useState(false);
    const handleRedirectToProductWithReview = () => {
        if (handleClickProduct) return handleClickProduct();

        navigate(`/my-product?productId=${id}`);
    }

    return (
        <div onClick={handleRedirectToProductWithReview}
             className="w-full max-w-sm mx-auto rounded-md shadow-md overflow-hidden transform hover:scale-105 cursor-pointer">
            <div className='text-white bg-blue-300 absolute p-3 right-0 top-0'>x{units}</div>
            <div className="flex items-end justify-end h-56 w-full bg-cover"
                 style={{backgroundImage: `url("${image}")`}}>
            </div>
            <div className="px-5 py-3">
                <h3 className="text-gray-700 uppercase" style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical"
                }}>{title}</h3>
                <span className="text-gray-500 mt-2">{price} €</span>
                {review && (
                    <div>
                        <hr className='my-4'/>
                        <span className="text-gray-500 mb-2">Avis du client</span>
                        <div className="flex space-x-3">
                            {[1, 2, 3, 4, 5].map(i => {
                                const selectedMaybeClassName = review.rating >= i ? 'text-red-500' : 'text-gray-500';
                                return (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={`w-6 h-6 ${selectedMaybeClassName}`}
                                        viewBox="0 0 20 20" fill="currentColor">
                                        <path fill-rule="evenodd"
                                              d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                                              clip-rule="evenodd"/>
                                    </svg>
                                )
                            })}
                        </div>
                        <span className="text-gray-500 mt-2">{review.message}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

ProductWithReview.propTypes = {
    title: PropTypes.string,
    price: PropTypes.string
};

ProductWithReview.defaultProps = {
    title: '',
    price: ''
};

export default ProductWithReview;

import React, {useEffect, useState} from 'react';
import firebase from 'firebase';
import * as queryString from 'query-string';

import Signedlayout from '../components/Signedlayout';
import {skeletonProducts} from './my-products';
import VisitorLayout from '../components/VisitorLayout';
import ShoppingListReviewModal from '../components/ShoppingListReviewModal';
import {convertHashmapToArray} from '../utils';
import ProductWithReview from '../components/ProductWithReview';
import {getFirebase} from "../utils/signin";

function ShoppingList({location}) {
    const [isSignedIn, setIsSignedIn] = useState(false);
    const [userId, setUserId] = useState(null);
    const [shoppingList, setShoppingList] = useState(null);
    const [isOpenAddReviewModal, setIsOpenAddReviewModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [authorAlertIsSent, setAuthorAlertIsSent] = useState(false);
    const {shoppingListId} = queryString.parse(location.search);
    const [isLoadingMutation, setIsLoadingMutation] = useState(false);
    const [showError, setShowError] = useState(false);
    const firebase = getFirebase();
    useEffect(() => {
        if (!firebase) return;

        const unregisterAuthObserver = getFirebase().auth().onAuthStateChanged(user => {
            setIsSignedIn(!!user);
            if (!user) return;

            setUserId(user.uid);
        });
        const db = getFirebase().firestore();
        const shoppingListsRef = db.collection('shopping-lists');
        shoppingListsRef
            .doc(shoppingListId)
            .get().then((doc) => {
            if (!doc.exists) {
                return;//error;
            }

            console.log('doc.data()', doc.data())
            const {
                title,
                products,
                authorId,
                authorEmail
            } = doc.data();
            setShoppingList({
                id: shoppingListId,
                title,
                authorId,
                authorEmail,
                products: convertHashmapToArray(products),
            });

            setIsLoading(false);
        }).catch((e) => {
            handleShowError();
        });
        return () => unregisterAuthObserver();
    }, []);
    const handleShowError = () => {
        setShowError(true);
        setIsLoadingMutation(false);
        setIsOpenAddReviewModal(false);
        setTimeout(() => {
            setShowError(false);
        }, 3000);
    }
    const {
        id,
        title,
        products,
        authorId,
        authorEmail,
        budget,
    } = shoppingList || {};
    const shoppingListUrl = `https://deerhome.fr/shopping-list?shoppingListId=${shoppingListId}`;
    const userIsAuthor = isSignedIn && userId === authorId;
    const handleOpenAddReviewModal = (product) => {
        setIsOpenAddReviewModal(true);
        setSelectedProduct(product);
    }
    const sendAlertEmailToAuthor = () => {
        if (authorAlertIsSent) return;

        getFirebase().firestore().collection('mail').add({
            to: [authorEmail],
            from: 'DeerHome<no-reply@deerhome.fr>',
            message: {
                subject: `Nouveau retour client - Liste shopping ${title}`,
                html: `Hey\nVous avez reçus un nouveau retour client sur votre liste shopping ${shoppingListUrl}`,
            }
        }).then(() => {
            setAuthorAlertIsSent(true);
        });
    }
    const Layout = isSignedIn ? Signedlayout : VisitorLayout;

    return (
        <Layout>
            {isLoadingMutation && (
                <div
                    className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50 flex items-center justify-center">
                    <svg className="animate-spin ml-1 mr-3 h-28 w-28 text-blue-700"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor"
                                stroke-width="4"></circle>
                        <path className="opacity-75" fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                </div>
            )}
            <div className="pt-24 pb-24">
                <div className="container px-3 mx-auto">
                    {isLoading ? (
                        <div className=" p-4 max-w-2xl w-full mx-auto">
                            <div className="animate-pulse flex space-x-40">
                                <div className="gradient h-96 w-80"></div>
                                <div className="flex-1 space-y-4 py-1">
                                    <div className="h-4 gradient rounded w-60"></div>
                                    <div className="space-y-2">
                                        <div className="h-5 gradient rounded"></div>
                                        <div className="h-5 gradient rounded w-5/6"></div>
                                    </div>
                                    <div className="h-4 gradient rounded w-60"></div>
                                    <div className="space-y-2">
                                        <div className="h-4 gradient rounded"></div>
                                        <div className="h-4 gradient rounded w-5/6"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    ) : (<div className="w-full mx-auto">
                            <h3 className="text-gray-700 uppercase text-lg">Liste shopping: {title}</h3>
                            {/*<span className="text-gray-500 mt-3">{budget} €</span>*/}
                            <hr className="my-3"/>
                            <div className="bg-blue-100 border-t border-b border-blue-300 px-4 py-3"
                                 role="alert"
                                 style={{
                                     color: '#6fb8ff',
                                     background: '#6fb8ff17'
                                 }}
                            >
                                <p className="font-bold">En cliquant sur un des produits ci-dessous, Vous pouvez :</p>
                                <li className="text-sm">accéder au produit via son site marchand.</li>
                                <li className="text-sm">laisser un avis. Votre décorateur pourra ainsi prendre vos
                                    retours.
                                </li>
                            </div>
                            <div className="mt-2">
                                <label className="text-blue-900 text-sm" htmlFor="count">Produits
                                    :</label>
                                <div className='flex'>
                                    <span
                                        className="mt-3 text-sm text-gray-500">{products.length} Produits</span>
                                </div>
                            </div>
                            <div
                                className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-6 pb-6">
                                {isLoading && (
                                    skeletonProducts.map(() => (
                                        <div className=" p-4 max-w-2xl w-full mx-auto">
                                            <div className="animate-pulse flex space-x-40">
                                                <div className="gradient h-72 w-full"></div>
                                            </div>
                                        </div>
                                    ))
                                )}
                                {products.map((product) => {
                                    const {title, price, review, image, id, units} = product;
                                    return (
                                        <ProductWithReview
                                            units={units}
                                            review={review}
                                            title={title}
                                            price={price}
                                            image={image}
                                            id={id}
                                            handleClickProduct={!userIsAuthor && (() => handleOpenAddReviewModal(product))}
                                        />
                                    )
                                })}

                            </div>
                        </div>
                    )}
                </div>
            </div>
            <ShoppingListReviewModal
                isOpen={isOpenAddReviewModal}
                setIsOpen={setIsOpenAddReviewModal}
                id={shoppingListId}
                product={selectedProduct}
                setIsLoadingMutation={setIsLoadingMutation}
                handleShowError={handleShowError}
                sendAlertEmailToAuthor={sendAlertEmailToAuthor}
            />
            {showError && (
                <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded fixed bottom-6 w-2/6 left-0 right-0 ml-auto mr-auto"
                    role="alert">
                    <strong className="font-bold">Erreur! </strong>
                    <span className="block sm:inline">Une erreur est survenue, veuillez réessayer plus tard.</span>
                    <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                        <svg className="fill-current h-6 w-6 text-red-500" role="button"
                             xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 20 20"><title>Close</title><path
                            d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                      </span>
                </div>
            )}
        </Layout>
    );
}

export default ShoppingList;